<script>
	import LogoLink from './LogoLink.svelte';
	import { page } from '$app/stores';
</script>

<nav class="wrapper">
	<LogoLink href="/" />
	<ul>
		<li><a href="/#features">Features</a></li>
		<li><a href="/#pricing">Pricing</a></li>
		<li><a href="/signup">Sign up</a></li>
		<li><a href="/login">Log in</a></li>
	</ul>
</nav>

<style>
	.wrapper {
		padding-right: 30px;
	}
	nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 20px;
	}
	nav a {
		display: inline-flex;
		align-items: center;
		color: var(--text);
		text-decoration: none;
		margin: 0px;
		font-weight: 600;
		font-size: 1.2rem;
	}
	ul {
		list-style-type: none;
		margin: 0px;
		padding: 0px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 34px;
	}
	li {
		margin: 0px;
	}
	@media screen and (max-width: 550px) {
		nav {
			padding: 8px 10px;
		}
		nav a {
			font-size: 1rem;
		}
		ul {
			gap: 13px;
		}
		.wrapper {
			padding-right: 20px;
		}
	}
</style>
